@import "./styles/shared.scss";

/* You can add global styles to this file, and also import other style files */
body, html {
    background: $dark-grey-bg;
    font-family: $font-family;
    height: 100%;
    margin: 0px;
}

h1 {
    margin-block-start: 0px;
    margin-block-end: 0px;
}