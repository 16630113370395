

// Background colours
$dark-grey-bg: #1E1E1E;
$dark-grey-panel: #252526;
$dark-grey-side-bar: #333333;

// Text
$text-colour-highlighted: #E7E7E7;
$text-colour: #B1BBBB;

// Icons
$icon-color: #ccc;
$icon-hightlighted: #fff;